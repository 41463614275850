<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ kiosque.nom }}
              <span class="body-1">— {{ $t('edition.atelier') }}</span>
            </div>
          </template>
          <v-form>
            <v-text-field
              v-model="kiosque.nom"
              :label="$t('general.nom')"
            />
            <label>{{ $t('general.description') }}</label>
            <cnx-editeur
              :texte.sync="kiosque.description"
            />
          </v-form>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="mr-0"
              @click="onSauvegarder()"
            >
              {{ $t('general.sauvegarder') }}
            </v-btn>
          </template>
        </base-material-card>

        <boite-documents
          :kiosque="kiosque"
          @maj="chargerDonnées()"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              {{ $t('general.logo') }}
            </h6>
            <boite-media
              :media.sync="mediaLogo"
            />
            <h6 class="display-1 mb-1 grey--text">
              {{ $t('general.image') }}
            </h6>
            <boite-media
              :media.sync="mediaImage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
  .ck-editor__editable {
    min-height: 100px;
   }
</style>

<script>
  import BoiteMedia from './../composantes/BoiteMedia'

  import restApiService from '@/services/restApiService.js'
  import BoiteDocuments from './../composantes/BoiteDocuments'
  import CnxEditeur from '../composantes/CnxEditeur.vue'

  export default {
    components: {
      BoiteMedia,
      BoiteDocuments,
      CnxEditeur,
    },
    data:
      () => ({
        kiosque: {},
        fichiers: null,
        options: {},
        headers: [
          { text: 'Prénom', value: 'usager.prenom' },
          { text: 'Nom', value: 'usager.nom' },
          { text: 'Actions', value: 'actions' },
        ],
        mediaImage: {},
        mediaLogo: {},

      }),
    mounted () {
      this.chargerDonnées()
    },
    methods: {
      chargerDonnées () {
        restApiService
          .get(`/api/kiosques/${this.$route.params.idKiosque}`)
          .then((result) => {
            this.kiosque = result.data
            if (this.kiosque.mediaImage) {
              restApiService.getMedia(this.kiosque.mediaImage).then((m) => {
                this.mediaImage = m.data
              })
            }
            if (this.kiosque.mediaLogo) {
              restApiService.getMedia(this.kiosque.mediaLogo).then((m) => {
                this.mediaLogo = m.data
              })
            }
          })
          .catch((erreur) => alert(erreur))
      },
      onSauvegarder () {
        restApiService
          .put('/api/kiosques', this.kiosque)
          .then(() => {
            this.$router.push({ path: `/orka/kiosque/${this.kiosque.id}` })
          })
          .catch((erreur) => alert(erreur))
        if (Object.keys(this.mediaImage).length) {
          restApiService
            .put(`/api/kiosques/${this.kiosque.id}/media`, this.mediaImage)
            .catch((erreur) => alert(erreur))
        }
        if (Object.keys(this.mediaLogo).length) {
          restApiService
            .put(`/api/kiosques/${this.kiosque.id}/mediaLogo`, this.mediaLogo)
            .catch((erreur) => alert(erreur))
        }
      },
    },
    //
  }
</script>
